<template>
  <RealisasiVariableKpiDivisiForm mode="Tambah" module="Realisasi Variable KPI"> </RealisasiVariableKpiDivisiForm>
</template>

<script>
import RealisasiVariableKpiDivisiForm from './form';

const RealisasiVariableKpiDivisiAdd = {
  name: 'RealisasiVariableKpiDivisiAdd',
  components: { RealisasiVariableKpiDivisiForm },
};

export default RealisasiVariableKpiDivisiAdd;
</script>
